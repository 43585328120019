import React, { ReactElement } from 'react';
import { Apartment } from '../../../interfaces/common';
import Icon from '../Icon';
import floorPlanIcon from '../../../images/icons/floor-plan.svg';
// @TODO add svg type declaration
interface Props {
  size: Apartment['size'];
  isLarge?: boolean;
}

const SizeIcon = ({ size, isLarge }: Props): ReactElement => {
  return (
    <Icon icon={floorPlanIcon} text={``} isLarge={isLarge}>
      {size} m<sup>2</sup>
    </Icon>
  );
};

export default SizeIcon;

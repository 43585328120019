import React, { ReactElement } from 'react';

import { Apartment } from '../../../interfaces/common';
import Icon from '../Icon';
import euroIcon from '../../../images/icons/euro.svg';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  rent: Apartment['rent'];
  isLarge?: boolean;
}

const RentIcon = ({ rent, isLarge }: Props): ReactElement => {
  const intl = useIntl();
  return (
    <Icon
      icon={euroIcon}
      isHighlighted={true}
      text={intl.formatMessage(
        {
          id: 'apartment.rent',
        },
        { rent },
      )}
      isLarge={isLarge}
    />
  );
};

export default RentIcon;

import React, { PropsWithChildren, ReactElement } from 'react';
import { Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import { breakpoints, fontSize, fontWeight, spacing } from '../../styles';

const StyledListItem = styled.li<Pick<Props, 'isHighlighted' | 'isLarge'>>`
  text-decoration: none;
  list-style: none;
  font-size: ${({ isLarge }) => (isLarge ? fontSize.xl : fontSize.large)};
  font-weight: ${({ isHighlighted }) =>
    isHighlighted ? fontWeight.regular : fontWeight.light};
  text-align: left;

  max-width: calc(100% - ${spacing['8']});

  @media only screen and (min-width: ${breakpoints.sm}) {
    max-width: calc(100% - ${spacing['8']});
  }
`;

const StyledImg = styled.img<Pick<Props, 'isHighlighted' | 'isLarge'>>`
  margin: 0 ${spacing['2']} 0 0;
  width: ${({ isLarge }) => (isLarge ? spacing['6'] : spacing['5'])};
  opacity: ${({ isLarge }) => (isLarge ? 0.55 : 0.7)};

  @media only screen and (min-width: ${breakpoints.sm}) {
    width: ${({ isLarge }) => (isLarge ? spacing['8'] : spacing['6'])};
  }
`;

const StyledRowContainer = styled.div`
  padding: ${spacing['3']} 0;

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: ${spacing['2']} 0;
  }
`;
interface Props {
  icon: string;
  text: string;
  isHighlighted?: boolean;
  isLarge?: boolean;
}

const Icon = ({
  icon,
  text,
  isHighlighted,
  isLarge,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  // @TODO get rid of text prop all together and only use children
  return (
    <StyledRowContainer>
      <Row style={{ margin: 0 }} middle="xs">
        <StyledImg src={icon} isHighlighted={isHighlighted} isLarge={isLarge} />
        <StyledListItem isHighlighted={isHighlighted} isLarge={isLarge}>
          {children || text}
        </StyledListItem>
      </Row>
    </StyledRowContainer>
  );
};

export default Icon;

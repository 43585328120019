import React, { ReactElement } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Apartment } from '../../../interfaces/common';
import apartmentIcon from '../../../images/icons/apartment.svg';

import Icon from '../Icon';

interface Props {
  floor: Apartment['floor'];
  isLarge?: boolean;
}

// @TODO add ordinal to text

const FloorIcon = ({ floor, isLarge }: Props): ReactElement => {
  const intl = useIntl();
  return (
    <Icon
      icon={apartmentIcon}
      text={intl.formatMessage({ id: 'apartment.floor' }, { floor })}
      isLarge={isLarge}
    />
  );
};

export default FloorIcon;

import React, { ReactElement } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { purple } from '../../styles';

type Props = {
  isLoading: boolean;
};

const Spinner = ({ isLoading }: Props): ReactElement => {
  return <MoonLoader size={30} color={purple['1']} loading={isLoading} />;
};

export default Spinner;

import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import {
  borderRadius,
  breakpoints,
  fontSize,
  fontWeight,
  purple,
  purpleGradient,
  shadows,
  spacing,
  white,
} from '../../styles';
import Spinner from './Spinner';

interface Props {
  onClick?: () => void;
  size?: 'lg' | 'xl' | 'base' | 'sm';
  isLoading?: boolean;
}

const StyledButton = styled.button<Pick<Props, 'size'>>`
  background-image: linear-gradient(
    to right,
    ${purple['6']} 0%,
    ${purpleGradient['6']},
    ${purple['6']} 100%
  );

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-size: 200% auto;
  color: ${white};
  padding: ${spacing['3']} ${spacing['5']};
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.xl};
  border: none;
  margin: ${spacing['2']} 0;
  display: block;
  box-shadow: ${shadows['4']};
  border-radius: ${borderRadius.full};

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: ${spacing['3']} ${spacing['6']};
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-position: right center;
    box-shadow: 0;
  }
`;

const StyledSpinnerContainer = styled.div`
  min-width: ${spacing['12']};

  & > :first-child {
    margin: 0 auto;
  }
`;

const Button = ({
  onClick,
  children,
  isLoading,
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <StyledButton onClick={onClick} disabled={isLoading}>
      {isLoading ? (
        <StyledSpinnerContainer>
          <Spinner isLoading={isLoading} />
        </StyledSpinnerContainer>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;

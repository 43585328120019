import React, { ReactElement } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { formatDate } from '../../../services/dates';
import { Apartment } from '../../../interfaces/common';
import okIcon from '../../../images/icons/ok.svg';
import Icon from '../Icon';

interface Props {
  availableFrom: Apartment['availableFrom'];
  isLarge?: boolean;
}

const AvailableFromIcon = ({ availableFrom, isLarge }: Props): ReactElement => {
  const intl = useIntl();

  return (
    <Icon
      icon={okIcon}
      isHighlighted={true}
      text={intl.formatMessage(
        { id: 'apartment.availableFrom' },
        { date: formatDate(availableFrom) },
      )}
      isLarge={isLarge}
    />
  );
};

export default AvailableFromIcon;
